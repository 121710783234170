import React from 'react';
import { Link } from 'gatsby';
import slugify from '@/model/slugify';

export default function Tags({ tags, selectedTag }: { tags: string[], selectedTag?: string }): JSX.Element {
  return (
    <>
      {
        tags.map(tag => (
          <Link
            className={`text-xs border-2 border-clay px-4 py-1 rounded-full font-title mr-2 mb-2 hover:bg-clay hover:text-offwhite transition-all ${tag === selectedTag ? 'text-offwhite bg-clay' : 'text-clay'}`}
            key={tag}
            to={`/tags/${slugify(tag)}`}>
            #{tag}
          </Link>
        ))
      }
    </>
  );
}
