import React from 'react';
import Icon from 'supercons';
import { Link } from 'gatsby';

interface Pagination {
  humanPageNumber: number;
  previousPagePath?: string;
  nextPagePath?: string;
  numberOfPages: number;
}

export default function Pagination(props: Pagination) {
  const paginationGroups = getPaginationNumbers(props.humanPageNumber, props.numberOfPages);

  return (
    <div className="px-4 pt-8 pb-24 flex items-center justify-center sm:px-6 relative z-30">
      <nav className="font-title relative z-0 inline-flex rounded-md -space-x-px" aria-label="Pagination">
        {props.previousPagePath && (
          <Link
            className="flex items-center hover:text-clay"
            to={props.previousPagePath}
            rel="prev">
            <Icon
              className="mr-4"
              glyph="back"
              size={32} />
          </Link>
        )}

        {props.numberOfPages > 5 &&
          (paginationGroups.map((number, index) => {
            if (number !== 1 && paginationGroups[index - 1] + 1 !== number) {
              return <span>...</span>
            }

            return getPaginationLink(number, props.humanPageNumber);
          }))
        }

        {props.nextPagePath && (
            <Link
              className="flex items-center hover:text-clay"
              to={props.nextPagePath}
              rel="next">
              <Icon
                className="ml-4"
                glyph="enter"
                size={32} />
            </Link>
          )}
      </nav>
    </div>
  );
}

function getPaginationLink(number: number, currentPage: number) {
  return (
    <Link
      key={number}
      to={`/posts${number > 1 ? `/${number}` : ''}`}
      className={`relative inline-flex items-center px-4 py-2 text-sm hover:text-clay ${
        currentPage === number ? 'text-clay' : ''
      }`}>
      {number}
    </Link>
  )
}

function getPaginationNumbers(currentPage: number, numberOfPages: number) {
  const last = numberOfPages - 1;

  const groups = [
    [1, 2, 3],
    [],
    [last - 2, last - 1, last],
  ];

  if (currentPage > 3 && currentPage < last - 2) {
    groups[1] = [currentPage - 1, currentPage, currentPage + 1];
  }

  return [...new Set([...groups[0], ...groups[1], ...groups[2]])];
}
