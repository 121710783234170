import React, { useState } from 'react';
import Icon from 'supercons';
import { formatDate } from '@/model/format-date';
import { Link } from 'gatsby';
import Tags from '@/components/tags';

export interface Node {
  frontmatter: {
    publish_date: string;
    tags: string[];
    title?: string;
  };
  fields: {
    slug: string;
  };
  excerpt: string;
}

export default function Post({ node, selectedTag }: { node: Node, selectedTag?: string }) {
  const [hovering, setHover] = useState(false);

  return (
    <div
      className={`post-list-item p-8 block ${hovering ? 'border-wheat' : 'border-transparent'} border-2 transition-all rounded-sm`}
      key={node.fields.slug}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <p className="mb-2 text-sm flex flex-wrap">
        <Tags tags={node.frontmatter.tags} selectedTag={selectedTag} />
      </p>

      <h2 className="mb-2 text-xl font-extrabold leading-snug tracking-tight text-gray-800 md:text-3xl">
        <Link className="hover:opacity-75 transition-all" to={`/posts${node.fields.slug}`}>
          {node.frontmatter.title || formatDate(node.frontmatter.publish_date)}
        </Link>
      </h2>

      <p className="mb-4 text-base">
        {node.excerpt}
      </p>

      <Link
        className="post-link text-clay font-semibold inline-flex items-center mt-4"
        to={`/posts${node.fields.slug}`}>
        Continue Reading

        <Icon
          className="ml-2"
          glyph="enter"
          size={32} />
      </Link>
    </div>
  );
}
