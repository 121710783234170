import React from 'react';
import Layout from '@/components/layout';
import SEO from '@/components/seo';
import Pagination from '@/components/pagination';
import Post, { Node } from '@/components/post';
import { graphql } from 'gatsby';

interface QueryProps {
  allMarkdownRemark: {
    edges: {
      node: Node;
    }[];
  };
}

export default function tags({ data, pageContext }: { data: QueryProps, pageContext: any }) {
  const posts = data.allMarkdownRemark.edges.map(({ node }) => <Post node={node} selectedTag={pageContext.tag} />);

  return (
    <Layout>
      <SEO title="Farm Journal" />

      <div className="mx-auto" style={{ maxWidth: '900px' }}>
        <h1
          className="pt-8 text-4xl font-bold md:text-5xl text-center"
          title="Farm Journal">
          Farm Journal
        </h1>

        <section className="w-full px-4 py-12 mx-auto max-w-7xl md:w-3/4">
          {pageContext.pageNumber === 0 && (
            <p className="pb-8">
              By tag homie
            </p>
          )}

          {posts}
        </section>

        <Pagination {...pageContext} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___publish_date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            publish_date
            tags
          }
          excerpt(pruneLength: 200, truncate: true)
        }
      }
    }
  }
`
